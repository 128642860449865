import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import {
	mapEdgesToNodes,
	filterOutDocsWithoutSlugs,
	filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PortableText from '../components/portableText'
import { ContactSection } from '../components/sections/contact-section'
import { TrainingCarouselSection } from '../components/sections/training-carousel-section'
import { TherapistCard } from '../components/cards/therapistCard'

export const query = graphql`
  query TrainingPageQuery($id: String!, $trainingId: String) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    },
    page: sanityTrainingPage(id: {eq: $id}) {
        id
        slug {
          current
        }
        showBanner
        seoTitle
        seoKeywords
        seoDescription
        bannerTitle
		bannerSubTitle
		hideOverlay
        bannerImage {
          asset {
			url
			fluid {
				...GatsbySanityImageFluid
			}
          }
        }
        bannerCta {
          label
          url
        }
        training {
		  name,
		  description,
		  price,
		  disclaimer,
		  unit,
		  _rawContent
        }
	}
	therapistPages: allSanityTherapistPage(filter: {
		therapist: {
      trainings:{
        elemMatch:{
            training: {
              id:
                {
              		eq: $trainingId
              	}
            	}
          }
        }
      }
	}) {
		edges {
			node {
				slug {
					current
				}
				therapist {
					firstName,
					lastName,
					_rawDescription,
					specialisations,
					location,
					profilePhoto {
						asset {
							url
							fluid {
								...GatsbySanityImageFluid
							}
						}
					}
				}
			}
		}
	}
  }
`

const TrainingPage = ({ data = {}, errors }) => {

	if (errors) {
		return (
			<Layout>
				<GraphQLErrorList errors={errors} />
			</Layout>
		)
	}

	const site = data.site || {}
	const allPages = (data.allPages || {}).edges || []
	const therapistPages = (data.therapistPages || {}).edges || []
	const { training = {} } = data.page || {}
	const { name, description, price, unit, _rawContent, period = "", disclaimer = "" } = training

	if (!site) {
		throw new Error(
			'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
		)
	}

	return (
		<Layout page={data.page || {}}>
			<Container>
				<section className="py-16 px-4 md:px-2 4xl:px-0 bg-white">
					<div className="max-w-6xl m-auto flex flex-col md:flex-row justify-around">
						<div className="flex-3">
							<h2 className="font-roboto-slab text-5xl text-gray-900">
								<span className="leading-tighter">Wat houdt {name} in?</span>
								{/* <span className="block -mt-1 text-gray-700 text-2xl">{"onderittel"}</span> */}
							</h2>
							<div className="mt-4">
								<PortableText blocks={_rawContent} />
							</div>
						</div>
						<div className="flex-1 ml-0 md:ml-8">
							<div className="mt-8 md:mt-0">
								<h4 className="font-roboto-slab text-xl text-orange-400">Prijs</h4>
								<span className="block mt-1 text-gray-600 font-bold text-lg tracking-wide">{price} <span className="mt-1 text-gray-400 font-medium text-base tracking-wide">{unit}</span></span>
								{disclaimer && <div className="mt-4 text-sm text-gray-400" >{disclaimer}</div>}
							</div>
							{period && <div className="mt-8 sm:mt-0 md:mt-8">
								<h4 className="font-roboto-slab text-xl text-orange-400">Doorlooptijd</h4>
								<span className="block mt-1 text-gray-600 font-bold tracking-wide">{period}</span>
							</div>}
						</div>
					</div>
				</section>
				{therapistPages.length > 0 && <section className="py-16 px-2 md:px-4 4xl:px-0 bg-gray-50">
					<div className="max-w-6xl mx-auto px-4">
						<h3 className="font-roboto-slab text-3xl text-gray-800">
							Therapeuten voor {name}
						</h3>
						<div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 max-w-6xl mx-auto">

							{
								therapistPages.map(({ node }) => {

									return (<TherapistCard node={node} />)


								})
							}
						</div>
					</div>
				</section>}
				<TrainingCarouselSection />
				{/* <section className="py-16 px-2 md:px-4 4xl:px-0 bg-white">
					<div className="max-w-6xl m-auto flex flex-col md:flex-row justify-around">
						{
							allPages.map(({ node = {} }) => {
								const { slug = {}, training = {}, bannerImage = {} } = node
								const { current } = slug
								const { name } = training
								const { asset = {} } = bannerImage || {}
								const { url, fluid } = asset

								return (
									<div className="flex-1 flex flex-col items-center mt-16 md:mt-0">
										<Img className="h-24 w-24 bg-gray-300 rounded-full object-cover" fluid={fluid} />
										<span className="font-roboto-slab mt-4 text-lg uppercase text-center">{name}</span>
										<Link to={current} className="mt-8 text-orange-400 text-center" href="">Lees meer →</Link>
									</div>
								)
							})
						}

					</div>
				</section> */}
				<ContactSection />
			</Container>
		</Layout>
	)
}

export default TrainingPage