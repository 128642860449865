import React from 'react'
import Img from 'gatsby-image'
import { graphql, StaticQuery, Link } from 'gatsby'
import PortableText from '../portableText'
import TrainingPage from '../../templates/training-page'
import BackgroundImage from 'gatsby-background-image'

export const TrainingCarouselSection = () => {

    return (
        <StaticQuery
            query={trainingCarouselQuery}
            render={({ pages = [] }) => {
                const { edges: trainingPages = [] } = pages

                return (
                    <>
                        <section className="bg-white even:bg-gray-50 flex flex-col md:flex-row">
                            {
                                trainingPages.map(({ node }) => {

                                    const { training = {}, slug = {}, previewImage: bannerImage = {}, previewImageHideOverlay: hideOverlay = false } = node
                                    const { asset = {} } = bannerImage || {}
                                    const { url, fluid } = asset

                                    const { name: trainingName, description, id } = training
                                    const { current } = slug

                                    let backgroundImageStack = [fluid]

                                    if (!hideOverlay) {
                                        backgroundImageStack.push("linear-gradient(rgba(0, 0, 40, 0.6), rgba(0, 0, 40, 0.6))")
                                    }

                                    return (
                                        <Link to={current} key={"training_" + id} className="block flex-1" style={{}}>
                                            <BackgroundImage
                                                Tag="div"
                                                fluid={backgroundImageStack.reverse()}
                                                style={{
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}
                                                className={"flex flex-col justify-center items-center h-full py-8 px-4 md:py-64"}
                                            >
                                                <div>
                                                    <h3 className="font-roboto-slab text-white text-2xl uppercase text-center">{trainingName}</h3>
                                                    <p className="pt-8 mt-8 border-t border-orange-400 text-white uppercase text-center leading-8 h-24">{description}</p>
                                                    <span className="block mx-auto mt-8 px-8 py-2 text-orange-400 text-white text-center font-semibold rounded uppercase tracking-widest">
                                                        Lees meer &rarr;</span>
                                                </div>
                                            </BackgroundImage>
                                        </Link>
                                    )
                                })
                            }
                        </section>
                    </>
                )
            }}
        />
    )
}

const trainingCarouselQuery = graphql`
    query trainingCarouselQuery {
        pages: allSanityTrainingPage {
            edges {
                node {
                    id,
                    slug {
                        current
                    },
                    hideOverlay,
                    bannerImage {
                        asset {
                            fluid {
                                ...GatsbySanityImageFluid
                            }
                            url
                        }
                    },
                    previewImage {
                        asset {
                            fluid {
                                ...GatsbySanityImageFluid
                            }
                            url
                        }
                    },
                    previewImageHideOverlay
                    training {
                        id
                        name
                        description
                    }
                }
            }
        }
    }
`


