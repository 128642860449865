import React from 'react'
import { graphql, Link } from 'gatsby'
import {
	mapEdgesToNodes,
	filterOutDocsWithoutSlugs,
	filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { FearCard } from '../components/cards/fearCard'
import { TrainingCarouselSection } from '../components/sections/training-carousel-section'

export const query = graphql`

  query TrainingsOverviewPageQuery {
      page: sanityPlainPage(_id: { eq: "trainingsOverviewPage" }) {
        id
        slug {
          current
        }
        showBanner
        seoTitle
        seoKeywords
		seoDescription
		hideOverlay
        bannerTitle
        bannerSubTitle
        bannerImage {
          asset {
			url
			fluid {
				...GatsbySanityImageFluid
			}
          }
        }
        bannerCta {
          label
          url
        }
      }
  }
`

const TrainingPage = ({ data = {}, errors }) => {

	if (errors) {
		return (
			<Layout page={data.page || {}}>
				<GraphQLErrorList errors={errors} />
			</Layout>
		)
	}

	const site = data.site || {}


	if (!site) {
		throw new Error(
			'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
		)
	}

	return (
		<Layout page={data.page || {}}>
			<Container>
				<TrainingCarouselSection />
			</Container>
		</Layout>
	)
}

export default TrainingPage
