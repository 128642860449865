import React from 'react'
import Img from 'gatsby-image'
import { graphql, StaticQuery } from 'gatsby'

export const ContactSection = ({ title = "Kom van je angsten af" }) => {

    return (
        <StaticQuery
            query={contactSectionQuery}
            render={({ site }) => {
                const { contactPhotoImage, contactName, contactPhone, contactEmail, contactFunction } = site
                const { asset = {} } = contactPhotoImage || {}
                const { fluid } = asset

                return (
                    <section className="bg-white even:bg-gray-50 py-16">
                        <div className="max-w-6xl mx-auto px-4 md:px-0">
                            <div>
                                
                                <div className="max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                                    <div className="lg:col-span-2">
                                        <div className="max-w-lg mx-auto">
                                            <h3 className="font-roboto-slab text-3xl text-gray-900">
                                                { title }
                                            </h3>
                                            <p className="mt-3 text-lg leading-6 tracking-wide text-gray-600">
                                                Voor al je vragen, boekingen en opmerkingen kan je contact opnemen met {contactName}.
                                            </p>
                                            <div className="mt-16 flex flex-col md:flex-row items-center md:items-start">
                                                <div className="h-36 w-36 ">
                                                    <Img className="block h-36 w-36 object-cover rounded-full shadow-lg" fluid={fluid} />
                                                </div>
                                                <div className="ml-0 mt-8 md:mt-0 md:ml-8 flex flex-col justify-center">
                                                    <div className="font-roboto-slab text-2xl text-gray-700">{contactName}</div>
                                                    <div className="font-roboto-slab text-base text-orange-400">{contactFunction}</div>
                                                    <dd className="mt-4 flex">
                                                        <svg className="flex-shrink-0 h-6 w-6 text-gray-400" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24">
                                                            <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                                        </svg>
                                                        <span className="ml-3">
                                                            {contactPhone}
                                                        </span>
                                                    </dd>
                                                    <dd className="mt-2 flex">
                                                        <svg className="flex-shrink-0 h-6 w-6 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                                        </svg>
                                                        <span className="ml-3">
                                                            {contactEmail}
                                                        </span>
                                                    </dd>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:col-span-3 mt-8 lg:mt-0">
                                        <div className="max-w-lg mx-auto lg:max-w-none">
                                            <form method="POST" action="/contact/gelukt" name="contact" netlify-honeypot="bot-field" netlify data-netlify="true" className="grid grid-cols-1 row-gap-6">
                                                <input type="hidden" name="bot-field" />
                                                <input type="hidden" name="form-name" value="contact" />
                                                <div>
                                                    <label for="full_name" className="sr-only">Volledige naam</label>
                                                    <div className=" rounded-md shadow-sm">
                                                        <input name="full_name" className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Volledige naam" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label for="email" className="sr-only">E-mailadres</label>
                                                    <div className="rounded-md shadow-sm">
                                                        <input name="email" type="email" className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150" placeholder="E-mailadres" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label for="phone" className="sr-only">Telefoonnummer</label>
                                                    <div className=" rounded-md shadow-sm">
                                                        <input name="phone" className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Telefoonnummer" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label for="message" className="sr-only">Bericht</label>
                                                    <div className=" rounded-md shadow-sm">
                                                        <textarea name="message" rows="4" className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150" placeholder="Bericht"></textarea>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <span className="inline-flex rounded-md shadow-sm">
                                                        <button type="submit" className="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-400 hover:bg-orange-300 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out">
                                                            Verzenden
                                                </button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }}
        />
    )
}

const contactSectionQuery = graphql`
query contactSectionQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
	  contactPhotoImage {
		  asset {
			  fluid {
				  ...GatsbySanityImageFluid
			  }
		  }
	  }
	  contactName,
	  contactFunction,
	  contactPhone,
	  contactEmail
    }
}
`