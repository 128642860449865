import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export const FearCard = ({ node = {} }) => {
    const { fear = {}, slug = {}, bannerImage = {} } = node
    const { current } = slug
    const { name: fearName, id, description } = fear
    const { asset = {} } = bannerImage || {}
    const { fluid = {} } = asset

    return (
        <Link to={current} className="flex flex-row rounded-lg shadow-md overflow-hidden">
            <div className="flex-2">
                <div className="h-full w-full">
                    <Img fluid={fluid} className="h-full w-full" />
                </div>
            </div>
            <div className="flex-3 bg-white px-6 py-4 flex flex-col justify-between">
                <div className="block" style={{ minHeight: "7.25rem" }}>
                    <h3 className="font-roboto-slab text-2xl leading-7 font-semibold text-orange-400">
                        {fearName}
                    </h3>
                    <p className="mt-3 text-lg leading-6 tracking-wide text-gray-600">
                        {/* <PortableText blocks={_rawDescription} /> */}
                        {description}
                    </p>
                </div>
                <div className="mt-4 text-right text-orange-400 font-semibold">Lees meer &rarr;</div>
            </div>
        </Link>
    )
}